import React, {useState} from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {useHistory} from 'react-router-dom'

const Actions = () => (
  <div>
      <div className="form-check form-check-primary mb-3">
            <i className={"mdi mdi-account-edit me-3"}></i>
        </div>

    </div>
)

const UserTable = () => {
    const [selectedFilter,setSelectedFilter] = useState('');
    const history = useHistory()


  const columns = [{
    dataField: 'name',
    text: 'Name',
    sort: true,
  }, {
    dataField: 'role',
    text: 'Role',
    sort: true
  }, {
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  // Table Data
  const productData = [
    { "role": "Admin","order_id": 1, "name": "Airi Satou", "bank_details": "Accountant", "city": "Tokyo", "open_order": "33", "actions":<Actions/>,  "amount": "162,700" },

    { "role": "Admin","order_id": 2, "name": "Angelica Ramos", "bank_details": "Chief Executive Officer (CEO)", "city": "London", "open_order": "47", "actions": <Actions/>, "amount": "1,200,000" },

    { "role": "Admin","order_id": 3, "name": "Ashton Cox", "bank_details": "Junior Technical Author", "city": "San Francisco", "open_order": "66", "actions": <Actions/>, "amount": "86,000" },

    { "role": "Admin","order_id": 4, "name": "Bradley Greer", "bank_details": "Software Engineer", "city": "London", "open_order": "41", "actions": <Actions/>, "amount": "132,000" },

    { "role": "Admin","order_id": 5, "name": "Brenden Wagner", "bank_details": "Software Engineer", "city": "San Francisco", "open_order": "28", "actions": <Actions/>, "amount": "206,850" },

    { "role": "Admin","order_id": 6, "name": "Brielle Williamson", "bank_details": "Integration Specialist", "city": "New York", "open_order": "61", "actions": <Actions/>, "amount": "372,000" },

    { "role": "Admin","order_id": 7, "name": "Bruno Nash", "bank_details": "Software Engineer", "city": "London", "open_order": "38", "actions": <Actions/>, "amount": "163,500" },

    { "role": "Admin","order_id": 8, "name": "Caesar Vance", "bank_details": "Pre-Sales Support", "city": "New York", "open_order": "21", "actions": <Actions/>, "amount": "106,450" },

    { "role": "Admin","order_id": 9, "name": "Cara Stevens", "bank_details": "Sales Assistant", "city": "New York", "open_order": "46", "actions": <Actions/>, "amount": "145,600" },

    { "role": "Admin","order_id": 10, "name": "Cedric Kelly", "bank_details": "Senior Javascript Developer", "city": "Edinburgh", "open_order": "22", "actions": <Actions/>, "amount": "433,060" },

    { "role": "Admin","order_id": 11, "name": "Marshall", "bank_details": "Regional Director", "city": "San Francisco", "open_order": "36", "actions": <Actions/>, "amount": "470,600" },

    { "role": "Admin","order_id": "Admin", "name": "Hurst", "bank_details": "Javascript Developer", "city": "San Francisco", "open_order": "39", "actions": <Actions/>, "amount": "205,500" },

    { "role": "Admin","order_id": 13, "name": "Rios", "bank_details": "Personnel Lead", "city": "Edinburgh", "open_order": "35", "actions": "2016", "amount": "217,500" },

    { "role": "Admin","order_id": 14, "name": "Snider", "bank_details": "Customer Support", "city": "New York", "open_order": "27", "actions": "201", "amount": "1,000" },

    { "role": "Admin","order_id": 15, "name": "Wilder", "bank_details": "Sales Assistant", "city": "Sidney", "open_order": "23", "actions": "20", "amount": "85,600" },

    { "role": "Admin","order_id": 16, "name": "Camacho", "bank_details": "Support Engineer", "city": "San Francisco", "open_order": "47", "actions": "207", "amount": "87,500" },

    { "role": "Admin","order_id": 17, "name": "Green", "bank_details": "Chief Operating Officer (COO)", "city": "San Francisco", "open_order": "48", "actions": "2011", "amount": "850,000" },

    { "role": "Admin","order_id": 18, "name": "Winters", "bank_details": "Accountant", "city": "Tokyo", "open_order": "63", "actions": "20115", "amount": "170,750" },

    { "role": "Admin","order_id": 19, "name": "Cortez", "bank_details": "Team Leader", "city": "San Francisco", "open_order": "22", "actions": "2026", "amount": "235,500" },

    { "role": "Admin","order_id": 20, "name": "Joyce", "bank_details": "Developer", "city": "Edinburgh", "open_order": "42", "actions": "202", "amount": "92,575" },

    { "role": "Admin","order_id": 21, "name": "Gloria Little", "bank_details": "Systems Administrator", "city": "New York", "open_order": "59", "actions": "2000", "amount": "237,500" },

    { "role": "Admin","order_id": 22, "name": "Haley Kennedy", "bank_details": "Senior Marketing Desi,ner", "city": "London", "open_order": "43", "actions": "20/18", "amount": "313,500" },

    { "role": "Admin","order_id": 23, "name": "Hermione Butler", "bank_details": "Regional Director", "city": "London", "open_order": "47", "actions": "2011/03/21", "amount": "356,250" },

    { "role": "Admin","order_id": 24, "name": "Herrod Chandler", "bank_details": "Sales Assistant", "city": "San Francisco", "open_order": "59", "actions": "20/08/06", "amount": "137,500" },

    { "role": "Admin","order_id": 25, "name": "Hope Fuentes", "bank_details": "Secretary", "city": "San Francisco", "open_order": "41", "actions": "2010/02/", "amount": "109,850" },

    { "role": "Admin","order_id": 26, "name": "Howard Hatfield", "bank_details": "Office Manager", "city": "San Francisco", "open_order": "51", "actions": "2008//16", "amount": "164,500" },

    { "role": "Admin","order_id": 27, "name": "Jackson Bradshaw", "bank_details": "Director", "city": "New York", "open_order": "65", "actions": "2008/09/26", "amount": "645,750" },

    { "role": "Admin","order_id": 28, "name": "Jena Gaines", "bank_details": "Office Manager", "city": "London", "open_order": "30", "actions": "2008//19", "amount": "90,560" },

    { "role": "Admin","order_id": 29, "name": "Jenette Caldwell", "bank_details": "Development Lead", "city": "New York", "open_order": "30", "actions": "2011/09/03", "amount": "345,000" },

    { "role": "Admin","order_id": 30, "name": "Jennifer Acosta", "bank_details": "Junior Javascript Devel,per", "city": "Edinburgh", "open_order": "43", "actions": "2013/02/01", "amount": "75,650" }

  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 col-md-12">
                                <Row>
                                <Col className="p-0 m-0">
                                  <Button onClick={()=>history.push('/user-control/add')} style={{width:"95%"}} color="primary" >Invite User</Button>
                                </Col>
                                <Col className="p-0 m-0">
                                <Button  style={{width:"95%"}}  color="secondary">Export</Button>
                                </Col>
                              </Row>
                                    {/* <SearchBar
                                      {...toolkitProps.searchProps}
                                    /> */}
                                    {/* <Select
                                    value={selectedFilter}
                                    onChange={(e) => {
                                        setSelectedFilter(e)
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                    /> */}
                                    {/* <i className="bx bx-search-alt search-icon" /> */}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"order_id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap justify-content-between"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>

    </React.Fragment>
  )
}

export default UserTable
