import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  TabContent,
  TabPane,
  NavItem,
  Nav,
  NavLink,
  CardTitle,
  CardText,

  Input
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import classnames from "classnames"
import { useHistory} from 'react-router-dom'




const UserProfile = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))
  const [customActiveTab, setcustomActiveTab] = useState("1")


  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [customchkPrimary, setcustomchkPrimary] = useState(true)

  const [idx, setidx] = useState(1)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [dispatch, success])

  function handleValidSubmit(event, values) {
    dispatch(editProfile(values))
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Accounts |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Breakup" breadcrumbItem="Accounts" />
        <Row>
          <Col md="4">
            <h5>Next Payment Date</h5>
            <Card style={{height: "283px"}}>
              <CardBody>
                <Calendar
                  onChange={()=>{}}
                  // value={value}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <h5>Payment History</h5>
            <Card style={{height: "283px"}}>
              <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">By Date</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">By Month</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              From Date
                            </label>
                          </div>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              To Date
                            </label>
                          </div>
                          <Row>
                            <Col>
                              <Button color="primary" >Download</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              From Date
                            </label>
                          </div>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              To Date
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <h5>Invoice</h5>
            <Card style={{height: "283px"}}>
              <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">By Date</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">By Month</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              From Date
                            </label>
                          </div>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              To Date
                            </label>
                          </div>
                          <Row>
                            <Col>
                              <Button color="primary" >Download</Button>
                            </Col>
                            <Col>
                            <Button color="secondary">Submit</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              From Date
                            </label>
                          </div>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={customchkPrimary}
                              onChange={() => {
                                setcustomchkPrimary(!customchkPrimary)
                              }}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                            >
                              To Date
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col cd={4}>
            <h5>Total Captured Amount</h5>
            <Input placeholder="amount" type="number" />
          </Col>
          <Col cd={4} className="text-center">
            <Button onClick={() => history.push('/dispatch')} className="mt-4" color="primary" >Dispatch Amount</Button>
          </Col>
          <Col cd={4} className="text-center">
            <Button onClick={() => history.push('/refund')}  className="mt-4" color="primary" >Refund Amount</Button>
          </Col>
        </Row>

<br/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
