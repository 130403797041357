import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import MarketingUI from "../../pages/MarketingUI"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const token = JSON.parse(localStorage.getItem("authUser"))

      if (isAuthProtected && !token) {
        return (
          <>
            {console.log(props.location, "ttreuoeepko")}
            <Redirect
              // to={{ pathname: "/home", state: { from: props.location } }}
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          </>
        )
      } else if (
        isAuthProtected &&
        token.user_type.includes("guest_marketing")
      ) {
        // console.log("ttrruuueeee123")
        console.trace()
        return (
          <Redirect
            to={{
              pathname: "/list-ads-marketing",
              state: { from: props.location },
            }}
          />
        )
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
