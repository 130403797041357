import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge
} from "reactstrap"
import { withTranslation } from "react-i18next"

const Menu = props => {


  return (
    <React.Fragment>
      <Col onClick={props.onclick} lg={4}>
        <Card color={props.color} className="cursor-pointer text-white-50 h-90">

          <CardBody>
            {
              props.count &&
              <Badge color="secondary" style={{ position: 'absolute', top: 5, right: 5 }}>{props.count}</Badge>
            }
            <CardTitle className="mb-4 text-white">
              <i className={"mdi " + props.icon + " me-3"}></i>{props.title}
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

Menu.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Menu)
