import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  TabContent,
  TabPane,
  NavItem,
  Nav,
  NavLink,
  CardTitle,
  CardText,

  Input
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import 'react-calendar/dist/Calendar.css';
import classnames from "classnames"
import { useHistory} from 'react-router-dom';
import UserTable from './userTable';
import RoleTable from "./roleTable";





const UserControl = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))
  const [customActiveTab, setcustomActiveTab] = useState("1")


  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [customchkPrimary, setcustomchkPrimary] = useState(true)

  const [idx, setidx] = useState(1)


  function handleValidSubmit(event, values) {
    dispatch(editProfile(values))
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Control Panel |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="BrakeUp" breadcrumbItem="User Control Panel" />

          <Row>
            <Col lg="12">



            <Card>
              <CardBody>
              <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">User</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Role</span>
                      </NavLink>
                    </NavItem>

                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                            <UserTable/>

                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                            <RoleTable/>
                        
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
              </CardBody>
            </Card>

        </Col>
        </Row>
         


<br/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserControl)
