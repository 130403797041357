import React, { useEffect, useState } from "react";

// import { Box, Button, Image, Flex, InputGroup, InputLeftElement, div, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast, Heading, divarea, Divider } from "@chakra-ui/react";




export const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className=" container d-flex flex-column text-justify mt-5 mb-5   refundPolicy font_NunitoA">
            <div className="text-uppercase mt-5 mb-3 align-self-center ">
                <h2><b>About Us</b></h2>
            </div>


            <div className=" mt-4 mb-2 text-justify">
                <p className="text-justify" >Brakeup, also known as “Brake Upgrade” in full is an online eCommerce company that offers a wide variety of high-quality brake parts for any car model. We understand how hectic it is when searching for brake parts that are compatible with your vehicle, especially if your car has several versions or the model is rare and or car is a vintage one. This is why we redefined the process and made it easy for customers to find the exact brake specs they need. With your car's make, model name and year of manufacture, we can find the right part number of your brake parts for your car within seconds.</p>
            </div>

            <div className=" mt-4 mb-2">
                <p>At Brakeup, we are in partnership with top brake parts manufacturers and suppliers both within and outside of the country. We strongly believe in braking for safety. This is why we only list car brake parts that have passed through all the necessary QA tests and certifications. Our catalogue includes Brake Pads, Brake Rotors, Brake Fluids and other essential parts needed for car braking systems. Apart from finding the right part, our algorithm is also designed to proffer compatible brake parts solutions that suit your vehicle usage. So, why compromise on your safety and settle down when you can choose Brakeup for reliable braking solutions?</p>
            </div>



            <div className="text-uppercase mt-4 mb-3">
                <h5>Our Mission and Approach</h5>
            </div>
            <p>Our mission is to uphold the slogan “making roads safer” when delivering quality brake parts to every customer. Also, We are pledged to make the ownership experience for car users hassle-free. We strive to become one of the top leading eCommerce websites that connect customers to compatible auto parts that are right for their needs.</p>
            <p>Our approach is strictly based on providing the best user experience and customer care, safety and guaranteed compatibility on all car models. Our design and algorithm is enabled to find what you need within seconds. </p>

            <div className="text-uppercase mt-4 mb-2">
                <h5>Our Philosophy</h5>
            </div>
            <p>We believe in helping customers drive while ensuring safety. Having understood the grave consequences of having a faulty brake system when driving, we seek to bridge the gap between affordability and quality brake parts. Our customer-centric philosophy prioritizes the needs of every customer based on their vehicle usage.</p>

            <div className="text-uppercase mt-5">
                <h3>Why Choose BrakeUp?</h3>
            </div>


            <div className="text-uppercase mt-4 mb-2">
                <h5>Quality and Durability</h5>
            </div>
            <p>On our website we only list durable and tested brake parts that can stand the test of time.</p>

            <div className="text-uppercase mt-4 mb-2">
                <h5>User Experience</h5>
            </div>
            <h5>Our platform provides the best product searching experience. So, you can get what you need within seconds</h5>

            <div className="text-uppercase mt-4 mb-2">
                <h5>Instant Delivery</h5>
            </div>
            <p>We also guarantee an instant and hurdle-free delivery once you order any brake parts from our website.</p>

            <div className="text-uppercase mt-4 mb-2">
                <h5>Customer Support</h5>
            </div>
            <p className="mt-4 mb-5" >We also have a team of friendly customer support on standby to help you whenever you encounter any issues while ordering. </p>


        </div>

    )
}