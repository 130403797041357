import {
    Input
  } from "reactstrap";
import Select from "react-select";

export const CustomInput = (props) => {
    const { name,
        defaultValue, value,
        onChange, setSelectedPackage, type } = props;
    return (<Input
        name={name}
        value={value}
        defaultValue={defaultValue}
        type={type?type: "text"}
        className="form-control"
        onChange={e => {
            if (setSelectedPackage != undefined) {
                setSelectedPackage(e);
            }
            onChange(e);
        }}
    />)
}

export const CustomSelect = (props) => {
    const { options, name,
        defaultValue, value,
        onChange, setSelectedPackage } = props;
    return (<Select
        classNamePrefix="select"
        name={name}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={e => {
            if (setSelectedPackage != undefined) {
                setSelectedPackage(e);
            }
            onChange(e);
        }}
    />)
}