import React from "react"
import {
  Col,
  Button,
  Row
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import logo from "assets/images/logo.png"
import { AiFillEdit } from "react-icons/ai";

const AddNotifications = props => {
  const history = useHistory();
  const id = history.location.pathname.split("/").reverse()[0];
  return (
    <React.Fragment>
      <div className="" style={{ backgroundColor: "rgb(254 224 52)", height: "81px" }}>
        <Row>
          <Col xs={7}>
            <div className="p-4" style={{ color: "black" }}>
              <h5 >Welcome Back !</h5>
              <p></p>
            </div>
          </Col>
          <Col className="col-5 " style={{ textAlign: "right" }}>
            <img src={logo} alt="" style={{ height: "80px" }} />
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} style={{textAlign:"right",marginTop: "15px",paddingRight: "30px"}}>
            {/* <Button color="primary" onClick={(d) => history.push(`/my-business/${id}`)}>My Bussiness</Button> */}
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ backgroundColor: "white" , position: "absolute",top: "50%"}}>
            <center>
                <h1>Your Application is waiting for the approval from Admin
                  {/* <AiFillEdit style={{cursor:"pointer"}}
                onClick={()=> history.push(`/add-company/${id}`)}
                /> */}
                </h1>
                <h4>wait upto 24 hours and <Link to="/login">LOGIN</Link>  again </h4>
                
            </center>
        </Col>
        </Row>
    </React.Fragment>
  )
}

export default AddNotifications
