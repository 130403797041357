import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  TabContent,
  TabPane,
  NavItem,
  Nav,
  NavLink,
  CardTitle,
  CardText,
  CardSubtitle,
  Input
} from "reactstrap"
import moment from "moment";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import classnames from "classnames"
import { useHistory } from 'react-router-dom'
import { get } from "../../helpers/api_helper";




const Payment = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))
  const [customActiveTab, setcustomActiveTab] = useState("1")


  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [customchkPrimary, setcustomchkPrimary] = useState(true)

  const [idx, setidx] = useState(1)
  const [amount, setAmount] = useState({});
  const [zoholink, setZoholink] = useState('');

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("authUser"));

    get(`/api/v2/accounts/total_despatch_amount/`).then(res => {
      setAmount(res.data);
    }).catch(err => {
      setAmount({});
    })
    get(`/api/v2/accounts/despatch_amount/?id=${token.id}`).then(res => {

      setZoholink(res?.data[0]?.despatch_zoho_link)
      // console.log(res.data[0].despatch_zoho_link, "despatch_amount");
    })
  }, [])

  function handleValidSubmit(event, values) {
    dispatch(editProfile(values))
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Payments |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Breakup" breadcrumbItem="Payments" />
          <Row>
            <Col xl="3">
              <Card style={{ height: "150px" }}>
                <CardBody>
                  {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Next payment In</CardSubtitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">14 days</CardSubtitle> */}
                  <CardSubtitle tag="h6" className="mb-2 text-muted">Next Payment Date</CardSubtitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">{moment(amount?.payment_date?.$date).format("MM-DD-YYYY")}</CardSubtitle>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="3">
              <Card style={{ height: "150px" }}>
                <CardBody>
                  <CardTitle tag="h5">Total Despatch</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 text-muted">{amount?.total}</CardSubtitle>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <Col xl="12" style={{ textAlign: "center" }}>
              <Button outline color="primary" style={{ marginRight: "20px" }} onClick={() => history.push("/invoices")}>INVOICES</Button>
              <Button outline color="primary"
              // onClick={()=> history.push("/despatch")}
              onClick={()=> window.open(zoholink)}
              >DESPATCH DETAILS</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Payment)
