import React, { useEffect, useState } from "react";
import {
    Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Badge,
    DropdownItem,
    Row,
    Col,
    Table,
    Button,
    Input,
    Label
} from "reactstrap";
import MetaTags from "react-meta-tags";
function CancelOrder() {
    return (
        <React.Fragment>
            <div className="page-content" style={{ background: "white" }}>
                <MetaTags>
                    <title>Cancel Order| Brakeup</title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        <Col xs="6" >
                            <Input type="textarea" name="text" id="exampleText" placeholder="Reason for cancelation of order" style={{ height: "160px" }}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"20px", marginBottom:"20px"}}>
                        <Col>
                            <div className="mt-4 pt-3">
                                <h5 className="font-size-14 mb-3">Payment Received Mode(auto select)</h5>
                                
                                <div className="form-check mt-2">
                                    <Input
                                    type="checkbox"
                                    value={false}
                                    className="form-check-input"
                                    id={"debit-credit-card"}
                                    />
                                    <Label className="form-check-label" htmlFor={"debit-credit-card"}>
                                        Debit/credit card
                                    </Label>
                                </div>
                                <div className="form-check mt-2">
                                    <Input
                                    type="checkbox"
                                    value={false}
                                    className="form-check-input"
                                    id={"upi"}
                                    />
                                    <Label className="form-check-label" htmlFor={"upi"}>
                                       UPI
                                    </Label>
                                </div>
                                <div className="form-check mt-2">
                                    <Input
                                    type="checkbox"
                                    value={false}
                                    className="form-check-input"
                                    id={"net-banking"}
                                    />
                                    <Label className="form-check-label" htmlFor={"net-banking"}>
                                        Net banking
                                    </Label>
                                </div>
                                <div className="form-check mt-2">
                                    <Input
                                    type="checkbox"
                                    value={false}
                                    className="form-check-input"
                                    id={"pay-on-delivery"}
                                    />
                                    <Label className="form-check-label" htmlFor={"pay-on-delivery"}>
                                        Pay on delivery
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:"120px", float:"right"}}>
                    <div className="d-flex flex-wrap gap-2">
                    <Button
                        type="submit"
                        color="primary"
                        className=" ">Submit</Button>
                    </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CancelOrder;