import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"

import { withTranslation } from "react-i18next"



//redux

const TopCard = props => {



  return (
    <React.Fragment>
        <Col md="4" >
        <Card className="mini-stats-wid">
            <CardBody>
            <Media>
                <Media body>
                <p className="text-muted fw-medium">
                    {props.title}
                </p>
                <h4 className="mb-0">{props.description}</h4>
                </Media>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                    <i
                    className={
                        "bx " + props.iconClass + " font-size-24"
                    }
                    ></i>
                </span>
                </div>
            </Media>
            </CardBody>
        </Card>
        </Col>
    </React.Fragment>
  )
}

TopCard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(TopCard)
