import React, { useEffect, useState } from "react";

//import { Box, Button, Image, Flex, InputGroup, InputLeftElement, Text, useDisclosure, Radio, RadioGroup, Stack, VStack, useToast, Heading, Textarea, Divider } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import "./CustomerCare.css"

// import { API_URL } from "../../helpers/api_helper";


export const SellerCare = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // const {
    //     handleSubmit,
    //     register,
    //     control,
    //     formState: { errors, isSubmitting },
    // } = useForm({ mode: 'onSubmit' });


    const onSubmit = () => { }

    return (
        <>

            {/* <Flex className="desktop_only" flexDirection='column' marginLeft={"10vw"} marginRight={"10vw"} mt="60px" mb="60px" alignItems='center' >
                <Flex width='100%'>
                    <Flex flexDirection='column' alignItems='flex-start' width='100%'>
                        <Text className="cuscare_headers" >SellerCare</Text>
                        <Text className="cuscare_header_2" mt="1rem"  > Get in touch with us!</Text> */}


                    <div className=" container d-flex flex-column text-justify mt-5 mb-5   refundPolicy font_NunitoA">
            <div className="text-uppercase mt-5 mb-3 align-self-center ">
                <h2><b>SellerCare</b></h2>
            </div>

        </div>

                        {/* <Flex mt='10px' mb='30px' width='100%'>
                            <form className="cuscare_form">

                                <textarea className="cuscare_descriptionbox"
                                    placeholder="Type your message here!"
                                    name="description"
                                    ref={register({
                                        required: true,
                                    })}
                                />
                                <button style={{alignSelf:"flex-end"}} onClick={handleSubmit((d) => onSubmit(d))} className="submit_msg_cuscare">SEND MESSAGE</button>

                            </form>
                        </Flex>
                    </Flex>

                    <Box className="cuscare_lottie">
                        <lottie-player
                            src={API_URL + "/api/v2/json/Brakeup_conatct_us.json"}
                            background="transparent"
                            speed="1"
                            loop
                            autoplay
                        ></lottie-player>
                    </Box>
                </Flex>
                <Flex alignSelf='flex-start' flexDirection='column' mb="60px" >
                    <Text className="cuscare_header_2" mt="1rem"  > Email : care@brakeup.in</Text>
                    <Text className="cuscare_header_2" mt="0.3rem"  > Phone : +91 70 2580 2580</Text>

                </Flex>

            </Flex>


 */}

            {/* MOBILE */}

            {/* <Box className="mobile_only" bg="white" pr={2} pl={2}   >
                <Flex flexDirection='column' alignItems='center' mt="50px" mb="60px" width='100%'>
                    <Text className="cuscare_headers"  >Customer Care</Text>
                    <Text className="cuscare_header_2" mt="5px"  > Get in touch with us!</Text>

                    <Box className="cuscare_lottie">
                        <lottie-player
                            src={API_URL + "/api/v2/json/Brakeup_conatct_us.json"}
                            background="transparent"
                            speed="1"
                            loop
                            autoplay
                        ></lottie-player>
                    </Box>

                    <Flex mt='30px' mb='30px' alignItems='center' width="95%" >
                        <form className="cuscare_form">

                            <textarea className="cuscare_descriptionbox"
                                placeholder="Type your message here!"
                                name="description"
                                ref={register({
                                    required: true,
                                })}
                            />
                            <button style={{alignSelf:"center"}} onClick={handleSubmit((d) => onSubmit(d))} className="submit_msg_cuscare">SEND MESSAGE</button>

                        </form>
                    </Flex>


                    <Text className="cuscare_header_2" mt="0.8rem" > Email : care@brakeup.in</Text>
                    <Text className="cuscare_header_2" mt="0.3rem" > Phone : +91 70 2580 2580</Text>



                </Flex>


            </Box> */}
        </>

    )
}