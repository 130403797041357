import React, { useState } from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "./customInputs";
import {
    Button,
    Label,
    Row,
    Col
} from "reactstrap";
import { submit } from "redux-form";
import { useEffect } from "react";
import { post, get } from "../../helpers/api_helper";

const AddVichle = (props) => {
    const { handleSubmit: handleSubmit2
        , errors: errors2, control: control2, register, watch} = useForm({
        mode: 'onBlur'
    });
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const onSubmit = (values) => {
        if(values.hasOwnProperty("isPresent") == true){
            values.isPresent = Boolean(values.isPresent)
            if(values.isPresent ==true){
                values.compatible_vehicle_to_year = null
            }
        }
        props.vichleDetails(values);
    }
    const getMake = async()=>{
        const result = await get("/api/v2/vehiclemake/");
        if(result.data){
            let inputs = [];
            result.data && result.data.map(item=>{
                if(typeof(item.make) === "string"){
                    inputs.push({
                        label: item.make,
                        value: item._id["$oid"]
                    })
                }
            })
          setMake(inputs)
        }else{
          setMake([]);
        }
    }
    const getModel = async(id)=>{
        const result = await get(`/api/v2/vehiclemodel/?id=${id.value}`);
        if(result.data){
            let inputs = [];
            result.data && result.data.map(item=>{
                inputs.push({
                    label: item.model,
                    value: item._id["$oid"]
                })
            })
            setModel(inputs)
        }else{
            setModel([]);
        }
    }
    useEffect(()=>{
        getMake();
    },[])
    const presentItem = watch("isPresent", "false");
    return (
        <form>
            <Row>
            <h4>Compatible vehicles</h4>
            <Col lg="4">
            <div className="mb-3">
                <Label htmlFor="productname">Make</Label>
                <Controller
                    as={<CustomSelect />}
                    defaultValue={null}
                    name={"compatible_vehicle_make_id"}
                    options={make}
                    onChange={([e]) => {
                        return { value: e };
                    }}
                    control={control2}
                    rules={{ required: true }}
                    setSelectedPackage={getModel}
                />
                {errors2.compatible_vehicle_make_id && <span style={{ color: "red" }}>Make is required.</span>}
            </div>
            </Col>
            <Col lg="4">
            <div className="mb-3">
                <Label htmlFor="productname">Model</Label>
                <Controller
                    as={<CustomSelect />}
                    defaultValue={null}
                    name={"compatible_vehicle_model_id"}
                    options={model}
                    onChange={([e]) => {
                        return { value: e };
                    }}
                    control={control2}
                    rules={{ required: true }}
                />
                {errors2.compatible_vehicle_model_id && <span style={{ color: "red" }}>Model is required.</span>}
            </div>
            </Col>
            <Col lg="4">
            <div className="mb-3">
                    <Label
                        htmlFor="horizontal-firstname-Input"
                    >
                        Fuel Type
                    </Label>
                    <Col sm={12}>
                        <div className="form-floating mb-3">
                            <Controller
                                as={<CustomSelect />}
                                defaultValue={null}
                                name={"compatible_vehicle_fuel_type"}
                                options={[
                                    { value: "Petrol", label: "Petrol" },
                                    { value: "Diesel", label: "Diesel" },
                                    { value: "Electric", label: "Electric" }]}
                                onChange={([e]) => {
                                    return { value: e };
                                }}
                                control={control2}
                                rules={{ required: true }}
                            />
                            {errors2.compatible_vehicle_fuel_type && <span style={{ color: "red" }}>fuel type is required.</span>}

                        </div>
                    </Col>
                </div>
            </Col>
            <Col lg="3">
            <div className="mb-3">
                <Label htmlFor="productname">From year</Label>
                <Controller
                    as={<CustomInput />}
                    defaultValue={null}
                    name="compatible_vehicle_from_year"
                    onChange={([e]) => {
                        return { value: e };
                    }}
                    type="number"
                    control={control2}
                    rules={{ required: true }}
                />
                {errors2.compatible_vehicle_from_year && <span style={{ color: "red" }}>From year is required.</span>}
            </div>
            </Col>
            <Col sm="2">
                <div className="mb-3">
                    <Label htmlFor="productname"></Label><br></br>
                    <input
                        ref={register({ required: false })}
                        name={"isPresent"}
                        value={"true"}
                        type="checkbox"
                        />
                        <span>Present</span>
                </div>
            </Col>
            {presentItem ==false &&<Col lg="3">
            <div className="mb-3">
                <Label htmlFor="productname"> To year</Label>
                <Controller
                    as={<CustomInput />}
                    defaultValue={null}
                    name="compatible_vehicle_to_year"
                    onChange={([e]) => {
                        return { value: e };
                    }}
                    type="number"
                    control={control2}
                    rules={{ required: true }}
                />
                {errors2.compatible_vehicle_to_year && <span style={{ color: "red" }}>To year is required.</span>}
            </div>
            </Col>}
            <Col lg="3" style={{marginTop:"26px"}}>
            <Button color="primary" onClick={handleSubmit2((d) => onSubmit(d))}>Add</Button>
            </Col>
            </Row>
        </form>
    )
}
export default AddVichle;