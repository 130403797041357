import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"



// import images
import logo from "assets/images/logo.png"


const AddUser = props => {
  const dispatch = useDispatch()

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  // handleValidSubmit
  const handleValidSubmit = async(event, values) => {

  }


  return (
    <React.Fragment>
      <MetaTags>
        <title>Login |  BrakeUp</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="" style={{backgroundColor: "rgb(254 224 52)"}}>
                  <Row>
                    <Col xs={7}>
                      <div className="p-4" style={{color: "black"}}>
                        <h5 >Invite User</h5>
                        {/* <p>Sign in to continue to Brakeup.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value="admin@themesbrand.com"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="name"
                          value="123456"
                          type="name"
                          required
                          placeholder="Enter Name"
                        />
                      </div>


                      <div className="mt-3 d-grid d-flex justify-content-start">
                        <button
                          className="btn btn-primary btn-block m-2"
                          type="submit"
                        >
                         Send
                        </button>
                        <button
                          className="btn btn-primary btn-block  m-2"
                          type="submit"
                        >
                          Cancel
                        </button>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddUser)

AddUser.propTypes = {
  history: PropTypes.object,
}
