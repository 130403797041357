import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button
} from "reactstrap"
import classnames from "classnames"
import { isEmpty, map } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData } from "common/data"

//Import actions
import { getProducts as onGetProducts } from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { get } from "../../../helpers/api_helper";
import Select from "react-select";

const EcommerceProducts = props => {
  const dispatch = useDispatch()
  const { history } = props
  const [productList, setProductList] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(5)
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    const result = await get("/api/v2/products/product/");
    if (result.data) {
      setProductList(result.data)
    } else {
      setProductList([]);
    }
  }
  useEffect(() => {
    getProducts();
  }, [])
  const handlePageClick = page => {
    setPage(page)
  }
  const onSearchChange = async (searchValue, filterValue) => {
    const token = JSON.parse(localStorage.getItem("authUser"))
    let url = "/api/v2/search/?q=product";
    if (searchValue) {
      url = `${url}&s=${searchValue}`
    }
    if (filterValue) {
      let filter = ""
      filterValue && filterValue.map(items => {
        if (items.value == "all") {
          filter = `${filter}&all=True`
        }
        if (items.value == "0") {
          filter = `${filter}&stock=0`
        }
        if (items.value == "in_active") {
          filter = `${filter}&inactive=False`
        }
        if (items.value == "active") {
          filter = `${filter}&active=True`
        }
        if (items.value == "low_stock") {
          filter = `${filter}&total_stock=5`
        }
      })
      url = `${url}${filter}`
    }
    setSearch(searchValue);
    setFilter(filterValue);
    try {
      const result = await get(url);
      console.log(result, 'resultresultresultresult')
      if (result) {
        setProductList(result.data)
      } else {
        setProductList([]);
      }
    } catch {
      setProductList([]);
    }

  }

  let NotApprovedBG = { filter: "blur(2px) brightness(90%)", backgroundColor: "transparent" }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products |  BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Commercial" breadcrumbItem="Products" />
          <Row>
            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6">
                  <div className="mt-2">
                    <Button color="primary" onClick={() => history.push("/ecommerce-add-product")}>NEW PRODUCT</Button>
                  </div>
                </Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => onSearchChange(e.target.value, filter)}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Select
                        style={{ width: "200px" }}
                        classNamePrefix="select"
                        name="filers"
                        placeholder="Filters"
                        isMulti
                        options={[{ label: "All Products", value: "all" },
                        { label: "Zero Stock Products", value: "0" },
                        { label: "Low Stock", value: "low_stock" },
                        { label: "in Active Products", value: "in_active" },
                        { label: "Active Products", value: "active" },
                        { label: "New Product Within Month", value: "new_product" },
                        { label: "Frequently Ordered", value: "frequently_ordered" },

                        ]}
                        onChange={e => {
                          onSearchChange(search, e)
                        }}
                      />
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <Col xl="4" sm="6" key={"_col_" + key}>
                      {product.isApproved ? "" :
                        <div style={{ position: "absolute", height: "100%", width: "100%", color: "#cc1616", fontSize: "1vw", fontWeight: 500, zIndex: 2, display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>
                          <div style={{ border: "5px solid", display: "flex", flexDirection: 'column', padding: "2vw", alignItems: "center", backgroundColor: "#fff", opacity: 0.8 }}>
                            <span>Waiting</span> <span>for</span> <span>approval</span>
                          </div>
                        </div>}
                      <Card style={product.isApproved ? {} : NotApprovedBG}
                        onClick={() => {
                          product.isApproved ?
                            history.push(
                              `/ecommerce-product-get-map/${product._id["$oid"]}`
                            ) : ""
                        }
                        }
                      >
                        <CardBody >
                          <div className="product-img position-relative">
                            {/* {product.isOffer ? (
                              <div className="avatar-sm product-ribbon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  {`-${product.offer}%`}
                                </span>
                              </div>
                            ) : null} */}

                            <img
                              src={product.image[0].startsWith("https://") ? product.image[0] : `https://${product.image[0]}`}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <span className="text-muted me-2">
                              Product Name
                            </span>
                            <h5 className="mb-3 text-truncate">
                              <Link
                                to={"/ecommerce-product-get-map/" + product._id.$oid}
                                className="text-dark"
                              >
                                {product.prod_name}
                              </Link>
                            </h5>
                            <span className="text-muted me-2">
                              Product Category
                            </span>
                            <h5 className="mb-3 text-truncate">{product.category}</h5>
                            <span className="text-muted me-2">
                              Product ID
                            </span>
                            <h5 className="mb-3 text-truncate">{product.product_id}</h5>
                            <span className="text-muted me-2">
                              Brand
                            </span>
                            <h5 className="mb-3 text-truncate">{product.brand}</h5>
                            <span className="text-muted me-2">
                              Type
                            </span>
                            <h5 className="mb-3 text-truncate">{product.type}</h5>
                            <span className="text-muted me-2">
                              Stock
                            </span>
                            <h5 className="mb-3 text-truncate">{product.total_stock}</h5>
                            <span className="text-muted me-2">
                              No of Sellers
                            </span>
                            <h5 className="mb-3 text-truncate">{product.no_of_sellers}</h5>

                            {/* <div className="text-muted mb-3">
                              <StarRatings
                                rating={product.rating}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#2D363F"
                                numberOfStars={5}
                                name="rating"
                                starDimension="14px"
                                starSpacing="3px"
                              />
                            </div> */}
                            {/* <h5 className="my-0">
                              <span className="text-muted me-2">
                                <del>${product.oldPrice}</del>
                              </span>
                              <b>${product.newPrice}</b>
                            </h5> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>

              {/* <Row>
                <Col lg="12">
                  <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        href="#"
                        onClick={() => handlePageClick(page - 1)}
                      />
                    </PaginationItem>
                    {map(Array(totalPage), (item, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink
                          onClick={() => handlePageClick(i + 1)}
                          href="#"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={page === totalPage}>
                      <PaginationLink
                        next
                        href="#"
                        onClick={() => handlePageClick(page + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(EcommerceProducts)
