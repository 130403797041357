import React, { useEffect, useState } from "react"

export const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container d-flex flex-column text-justify mt-5 mb-5   refundPolicy font_NunitoA">
      <div className="text-uppercase mt-5 mb-3 align-self-center ">
        <h2>
          <b>Terms of Use - Seller Agreement</b>
        </h2>
      </div>
      <div className="mt-4 mb-2 text-justify">
        <p className="text-justify">
          These Terms of Use (the "Agreement") constitute a legally binding
          agreement between Brakeup Internet Technologies Private Limited
          (referred to as "we," "us," or "our") and the individual or entity
          agreeing to these terms ("Seller" or "you") regarding your
          participation as a seller on our online marketplace (the
          "Marketplace").
        </p>
      </div>
      <div className=" mt-1 mb-2 text-justify">
        <p className="text-justify">
          By accessing or using the Marketplace, you acknowledge that you have
          read, understood, and agree to be bound by this Agreement. If you do
          not agree with any part of this Agreement, please do not use the
          Marketplace.
        </p>
      </div>
      <div className="text-uppercase mt-5 mb-3">
        <h5>Marketplace Services</h5>
      </div>
      <p>
        1. Seller Registration: To become a seller on the Marketplace, you must
        complete the registration process and provide accurate and complete
        information about yourself or your company as requested. You agree to
        maintain the accuracy of your registration information and promptly
        update any changes.
      </p>
      <p>
        2. Seller Responsibilities: As a seller, you are responsible for the
        products or services you offer, including their quality, accuracy,
        legality, and compliance with all applicable laws and regulations. You
        are solely responsible for any content, listings, and materials you
        provide or upload on the Marketplace.
      </p>{" "}
      <p>
        3. Transaction Process: The Marketplace provides a platform for sellers
        to list and sell their products or services to buyers. Sellers agree to
        comply with our policies and guidelines regarding listing, pricing,
        delivery, and order management. You understand and acknowledge that we
        are not a party to any transaction between you and the buyer, and we do
        not guarantee the availability, quality, or legality of any products or
        services offered on the Marketplace.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>Account Security</h5>
      </div>
      <p>
        1. Account Credentials: You are responsible for safeguarding your
        account credentials, including your username and password. You agree not
        to disclose your account information to any third party and promptly
        notify us of any unauthorized use of your account.
      </p>
      <p>
        2. Prohibited Activities: You agree not to engage in any activities that
        may compromise the security or integrity of the Marketplace, including
        but not limited to hacking, unauthorized access, or interference with
        other users' accounts or data.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>Intellectual Property</h5>
      </div>
      <p>
        1. Seller Content: By providing any content, including images,
        descriptions, logos, trademarks, or any other intellectual property, you
        grant us a non-exclusive, worldwide, royalty-free license to use,
        reproduce, modify, adapt, and display such content solely for the
        purpose of operating and promoting the Marketplace.
      </p>
      <p>
        2. Infringement Claims: We respect the intellectual property rights of
        others and expect sellers to do the same. If you believe that any
        content on the Marketplace infringes upon your intellectual property
        rights, you must promptly notify us, and we will take appropriate
        action.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>Fees and Payments</h5>
      </div>
      <p>
        1. Fees: We may charge fees for the use of certain features or services
        on the Marketplace. The applicable fees, payment terms, and payment
        methods will be outlined separately in our Fee Schedule.
      </p>
      <p>
        2. Payment Processing: We may use third-party payment processors to
        facilitate transactions on the Marketplace. You agree to comply with
        their terms and conditions. We are not responsible for any errors,
        delays, or losses related to payment processing.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>Termination</h5>
      </div>
      <p>
        1. Termination: We reserve the right to suspend or terminate your
        account or access to the Marketplace at any time for any reason, without
        prior notice or liability.
      </p>
      <p>
        2. Effects of Termination: Upon termination, all rights and licenses
        granted to you under this Agreement will immediately cease. You
        acknowledge and agree that we shall not be liable to you or any third
        party for any termination of your access to the Marketplace.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>Limitation of Liability</h5>
      </div>
      <p>
        1. Disclaimer: To the maximum extent permitted by law, we provide the
        Marketplace on an "as is" and "as available" basis, without warranties
        of any kind, whether express, implied, or statutory. We do not guarantee
        that the Marketplace will be error-free, secure, or uninterrupted.
      </p>
      <p>
        2. Indemnification: You agree to indemnify, defend, and hold us harmless
        from any claims, losses, liabilities, damages, costs, or expenses
        arising out of or related to your use of the Marketplace, violation of
        this Agreement, or infringement of any third-party rights.
      </p>
      <div className="text-uppercase mt-4 mb-2">
        <h5>General Provisions</h5>
      </div>
      <p>
        1. Entire Agreement: This Agreement constitutes the entire agreement
        between you and us regarding the subject matter herein and supersedes
        all prior or contemporaneous communications, whether oral or written.
      </p>
      <p>
        2. Governing Law: This Agreement shall be governed by and construed in
        accordance with the laws of State of Karnataka. Any disputes arising out
        of or in connection with this Agreement shall be subject to the
        exclusive jurisdiction of the courts located in Bangalore.
      </p>
      <p>
        3. Severability: If any provision of this Agreement is found to be
        invalid, illegal, or unenforceable, the remaining provisions shall
        continue in full force and effect.
      </p>
      <div className="mt-5 mb-1">
        <p>
          Please review these terms carefully. By using the Marketplace, you
          acknowledge that you have read, understood, and agreed to be bound by
          this Agreement.
        </p>
      </div>
      <div className="mt-1 mb-5">
        <p>
          By clicking 'Sign Up' or by accessing or using the Marketplace, you
          indicate your acceptance of this Agreement.
        </p>
      </div>
    </div>
  )
}
