import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table

} from "reactstrap"
import companies from "assets/images/companies"

import {Link} from 'react-router-dom';
import moment from 'moment';

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"




//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const BuyerRow = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const handleProjectClick = arg => {
    const project = arg

    setProjectList({
      id: project.id,
      img: project.img,
      name: project.name,
      description: project.description,
      status: project.status,
      color: project.color,
      dueDate: project.dueDate,
      team: project.team,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDeleteProject = project => {
    dispatch(onDeleteProject(project))
  }

  return (
    <React.Fragment>
                    <tr className={`tr-border-${props.color} mb-2`} onClick={props.onClick}>
                        
                          <td className='rounded' >
                            <Row>
                                <Col>
                                    <h5 className="text-truncate font-size-14">
                                    <Link
                                        to={`/orders/1`}
                                        className="text-dark"
                                    >
                                        Order ID : {props.order_id}
                                    </Link>
                                    </h5>
                                    <img
                                    src={props.image}
                                        alt=""
                                        className="avatar-sm"
                                        />
                                </Col>
                                <Col>

                                <p className="text-muted mb-0">
                                 Name : {props.prod_name}
                                </p>
                                <p className="text-muted mb-0">
                                Brand Name : {props.brand}
                                </p>
                                <p className="text-muted mb-0">
                                BrakeUp part number : {props.brakeup_part_no}
                                </p>
                                <p className="text-muted mb-0">
                                Product type : {props.type}
                                </p>
                                <p className="text-muted mb-0">
                                Product Category : {props.category}
                                </p>
                                </Col>
                            </Row>




                          </td>


                          <td>
                          <div className="text-muted mb-1 p-2 bordered-div text-center" >
                                Buyer Address :<br/>
                                {props.billing_address}
                            </div>
                            <div className="text-muted mb-1 p-2 bordered-div text-center" >
                                Seller Address :<br/>
                                {props.vendor_address}
                            </div>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                                Status : {props.order_status}
                            </div>
                           
                          </td>
                          <td> 
                          <div className="text-muted mb-1 p-2 bordered-div" >
                          Buyer Phone Number : {props.billing_phone}
                            </div>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                            Seller Phone Number : {props.vendor_phone}
                            </div>
                            <div className="text-muted mb-1 p-2 bordered-div" >
                            {/* New or Replacement Or der */}
                            </div>
                            </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="i"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  href="#"
                                  onClick={() => handleProjectClick()}
                                >
                                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={() => handleDeleteProject()}
                                >
                                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
    </React.Fragment>
  )
}

BuyerRow.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(BuyerRow)
