import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalBody,
} from "reactstrap"

import moment from "moment"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { get, post } from "../../helpers/api_helper"

import { error } from "toastr"

import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import ReactPaginate from "react-paginate"

import Select from "react-select"

import { InfinitySpin } from "react-loader-spinner"

export const InfinitySpinLoader = () => {
  return (
    <div
      className="orderloading2"
      style={{
        position: "fixed",
        width: "-webkit-fill-available",
        height: "100%",
        backdropFilter: "blur(2px)",
        backgroundColor: "#e4e4e433",
        zIndex: "9999",
        inset: "auto",
        overflow: "hidden",
      }}
    >
      <div
        className="Orderloading"
        style={{
          display: "block",
          position: "fixed",
          zIndex: 6,
          margin: "auto",
          top: "40%",
        }}
      >
        <InfinitySpin width="200" color="#243678" />
      </div>
    </div>
  )
}

const Orders = props => {
  const [newOrders, setNewOrders] = useState([])
  const [newOrderCount, setNewOrderCount] = useState(0)

  const [isLoaded, setisLoaded] = useState(false)
  const [isLoaded2, setisLoaded2] = useState(false)

  const [loader, setLoader] = useState(false)

  const [shippedOrders, setShippedOrders] = useState([])
  const [shippedOrderCount, setShippedOrderCount] = useState(0)
  const [completedOrders, setCompletedOrders] = useState([])
  const [completedOrderCount, setCompletedOrderCount] = useState(0)
  const [returnedOrders, setReturnedOrders] = useState([])
  const [returnedOrderCount, setReturnedOrderCount] = useState(0)

  const orderFilter = JSON.parse(sessionStorage.getItem("orderFilter" || []))

  useEffect(() => {
    getNewOrders()
    getOrders()
  }, [])

  useEffect(() => {
    if (loader) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [loader])

  const getNewOrders = async () => {
    setLoader(true)
    get(`api/v1/order/seller_neworder/`)
      .then(response => {
        setNewOrders(response.order ? response.order : [])
        setNewOrderCount(response?.totalcount)
        // setTotal(response.totalcount)
        setisLoaded(true)
        setLoader(false)
      })
      .catch(error => {})
    // const result = await get("/api/v2/order/openorder/");
    // console.log(result, "resultttt")
  }

  const getOrders = async () => {
    setLoader(true)
    get(
      `/api/v2/order/sellerorders/?completed_orders=completed&shipped_orders=shipped&returned_orders=returned`
    )
      .then(response => {
        setShippedOrders(response?.shipped_orders?.order)
        setShippedOrderCount(response?.shipped_orders?.totalcount)
        setCompletedOrders(response?.completed_orders?.order)
        setCompletedOrderCount(response?.completed_orders?.totalcount)
        setReturnedOrders(response?.returned_orders?.order)
        setReturnedOrderCount(response?.returned_orders?.totalcount)
        setisLoaded2(true)
        setLoader(false)
      })
      .catch(error => {
        setLoader(false)
      })
  }

  const onClickEvents = (event, item) => {
    setLoader(true)
    notifyToggle()
    let input = {
      order_status: event,
      vendor_id: item.vendor_id,
      order_id: item.order_id,
      prod_id: item.prod_id,
      logistic_partner: item.logistic_partner,
      shipment_mode: item.shipment_mode ?? "Surface",
    }
    let ordProid = {
      order_id: item.order_id,
      prod_id: item.prod_id,
    }

    // console.log(event, item)
    post("/api/v2/seller/seller/approve_orders/", input)
      .then(res => {
        if (event == "Invoiced") {
          post("/api/v2/seller/generatepdf/", ordProid)
            .then(res => {
              setLoader(false)
              SuccessModal(event, item)
              getNewOrders()
            })
            .catch(err => {
              error(err?.response?.data?.message)
            })
        } else if (event == "Labeled" && item.logistic_partner == "iCarry") {
          post("/api/v2/seller/generatelabel/", ordProid)
            .then(res => {
              setLoader(false)
              SuccessModal(event, item)
              getNewOrders()
            })
            .catch(err => {
              error(err?.response?.data?.message)
            })
        } else {
          setLoader(false)
          SuccessModal(event, item)
          getNewOrders()
        }
        // console.log(res, "approve_orders")
      })
      .catch(err => {
        error(err?.response?.data?.message)
        setLoader(false)
        if (err?.response?.data?.message == "ORDER CANCELLED") {
          getNewOrders()
        }
      })
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdownOpen = () => setDropdownOpen(prevState => !prevState)

  const OrderCard = item => {
    return (
      <div
        className="mb-4 p-3"
        style={{
          cursor: "default",
          boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.1)",
          width: "100%",
          border: "1px solid #E2E8F0",
          borderRadius: "4px",
        }}
      >
        <Row className="d-sm-inline-block d-inline-block d-md-flex justify-content-around mb-3">
          <Col>
            <Label className="mb-0 fw-bolder">ORDER ID: {item.order_id}</Label>
          </Col>
          <Col className="d-flex justify-content-center">
            <Label className="mb-0 fw-normal text-uppercase">
              DATE:{" "}
              {moment(item.created_at["$date"]).format("DD-MM-YYYY, h:mm:ss a")}
            </Label>
          </Col>
          <Col className="d-flex justify-content-start ju justify-content-md-center ">
            <Label className="mb-0 fw-normal text-truncate text-uppercase">
              BUYER{item?.is_pickupcompleted && " STATE"}:{" "}
              {item?.is_pickupcompleted ? "" : item?.buyer_name} ,
              {item?.buyer_city}
            </Label>
          </Col>
        </Row>
        <Row className="pe-2">
          <Col sm="6" md="3" lg="2">
            <img
              src={item.image && item.image.length > 0 ? item.image[0] : ""}
              alt=""
              className="avatar-sm"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                border: "1px solid #E1E4F1",
                borderRadius: " 6px",
              }}
            />
          </Col>
          <Col sm="5" md="5" lg="7">
            <Row className="h-100">
              <Col className="d-flex flex-column mt-md-0 mt-sm-4 ordertext">
                <p className="text-uppercase">{item.brand}</p>
                <p className="text-uppercase">{item.prod_name}</p>
                <p>Rs: {item?.price}</p>
                <p>QUANTITY: {item.quantity} Nos</p>
                <p className="text-uppercase">
                  SELLER PART NO: {item?.seller_part_no}
                </p>
                {/* <p>VIN: {props?.VIN}</p> */}

                <p className="text-uppercase">
                  {item.vehicle_details?.make} {item.vehicle_details?.model}{" "}
                  {item.vehicle_details?.year} {item.vehicle_details?.version}{" "}
                  {item.vehicle_details?.fueltype}
                </p>
              </Col>
              <Col className="d-flex flex-md-column flex-sm-row align-items-sm-center align-items-md-end w-sm-100 w-md-auto">
                <div className="d-none flex-md-column flex-sm-row justify-content-between p-0 h-100 w-sm-100 w-md-auto">
                  <Button
                    outline
                    style={{
                      border: "1px solid #243678",
                      color: "#000000",
                    }}
                    className="py-5 fw-bolder"
                  >
                    HELP
                  </Button>
                  <Button
                    outline
                    style={{
                      border: "1px solid #243678",
                      color: "#000000",
                    }}
                    className="p-3 mt-2 fw-bolder"
                  >
                    MEDIA
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="3" className="d-flex flex-column justify-content-between">
            <Row className="mb-2">
              <div className="d-flex justify-content-md-between justify-content-center p-0">
                <Button
                  style={
                    item.isInvoiced
                      ? {
                          backgroundColor: "#243678",
                          opacity: "0.7",
                        }
                      : {
                          backgroundColor: "#243678",
                        }
                  }
                  className="py-lg-4 py-md-3 py-4 px-0 px-sm-4 px-xl-4 fw-bold btnHoverOpacity"
                  onClick={() => {
                    isInvShpDowloaded("Invoiced", item)
                  }}
                >
                  {item.isInvoiced ? "DOWNLOAD" : "GENERATE"} INVOICE
                </Button>

                <Button
                  disabled={item.isInvoiced ? false : true}
                  style={
                    item.isLabeled
                      ? {
                          backgroundColor: "#243678",
                          opacity: "0.7",
                        }
                      : {
                          backgroundColor: "#243678",
                        }
                  }
                  className="py-lg-4 py-md-3 py-2 px-0 px-sm-4 px-xl-4 ms-2 fw-bold btnHoverOpacity"
                  onClick={() => {
                    isInvShpDowloaded("Labeled", item)
                  }}
                >
                  {item.isLabeled ? "DOWNLOAD" : "GENERATE"} SHIP LABEL
                </Button>
              </div>
            </Row>

            {!item.isShipready && (
              <Row>
                <Button
                  disabled={item.isLabeled ? false : true}
                  style={{
                    background: "#243678",
                    color: "#243678",
                  }}
                  className="fw-bold btnHoverOpacity btnfont"
                  onClick={() => {
                    NotifyModal("Pickup Scheduled", item)
                  }}
                >
                  SCHEDULE PICKUP
                </Button>
              </Row>
            )}
            <Row className="mt-3">
              <Button
                outline
                className="btnfont p-3 d-flex flex-column align-items-center text-dark"
                style={
                  item.isShipready
                    ? item?.is_pickupcompleted
                      ? {
                          background: "#FFF",
                          cursor: "default",
                        }
                      : {
                          background: "#06B825",
                          cursor: "default",
                        }
                    : {
                        background: "#F22020",
                        cursor: "default",
                      }
                }
              >
                {" "}
                <div className="btnfont">
                  STATUS :{" "}
                  <span className="fw-bolder text-uppercase text-truncate">
                    {item.seller_status}
                  </span>
                </div>
                {item.isShipready && (
                  <div className="mt-2 btnfont">
                    SHIPPER :{" "}
                    <span className="fw-bolder text-uppercase text-truncate">
                      {item?.shipper_name}
                    </span>
                  </div>
                )}
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  // Modal open state
  const [notifyModal, setNotifyModal] = useState(false)
  const [notifyEvent, setNotifyEvent] = useState([])

  // Toggle for Modal
  const notifyToggle = () => setNotifyModal(!notifyModal)

  const NotifyModal = (event, item) => {
    let data = {
      event: event,
      item: item,
    }
    // console.log(event, "eventtt")
    notifyToggle()
    if (event == "Invoiced") {
      data.message = "Order will be invoiced"
    } else if (event == "Labeled") {
      data.message = "Label will be generated"
    } else if (event == "Pickup Scheduled") {
      data.message = "Pickup will be scheduled"
    }
    setNotifyEvent(data)
  }

  // Modal open state success
  const [successModal, setSuccessModal] = useState(false)
  const [successEvent, setSuccessEvent] = useState([])

  // Toggle for Modal success
  const successToggle = () => setSuccessModal(!successModal)

  const SuccessModal = (event, item) => {
    let data = {
      event: event,
      item: item,
    }
    // console.log(event, "eventtt")
    successToggle()
    if (event == "Invoiced") {
      data.message = "Print & enclose invoice and seal the package"
    } else if (event == "Labeled") {
      data.message = "Print and paste outside the package"
    } else if (event == "Pickup Scheduled") {
      data.message = "Handover package when executive arrives"
    }
    setSuccessEvent(data)
  }

  //DOWLOAD Invoice

  const DownloadInvoice = item => {
    let downloadurl = item
    if (downloadurl && downloadurl?.length != 0) {
      setLoader(true)

      const filename = downloadurl.substring(downloadurl.lastIndexOf("/") + 1)

      fetch(downloadurl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then(response => response.blob())
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", filename)
          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })
        .catch(err => {})
    }
    setLoader(false)
  }

  const isInvShpDowloaded = (event, item) => {
    if (event == "Invoiced") {
      if (item.isInvoiced) {
        DownloadInvoice(item.invoice)
      } else {
        NotifyModal(event, item)
      }
    } else {
      if (item.isLabeled && item.logistic_partner == "iCarry") {
        DownloadInvoice(item.shipping_label)
      } else if (item.isLabeled) {
        window.open(item.shipping_label)
      } else {
        NotifyModal(event, item)
      }
    }
  }

  const [filterArray, setFilterArray] = useState(orderFilter ?? [])

  const onClickFilter = value => {
    if (filterArray.includes(value)) {
      let filterArrayy = filterArray.filter(item => item !== value)
      setFilterArray(filterArrayy)
      sessionStorage.setItem("orderFilter", JSON.stringify(filterArrayy))
    } else {
      setFilterArray([...filterArray, value])
      sessionStorage.setItem(
        "orderFilter",
        JSON.stringify([...filterArray, value])
      )
    }
  }

  return (
    <React.Fragment>
      {loader && <InfinitySpinLoader />}

      <div className="page-content fontorders">
        <MetaTags>
          <title>Orders | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Orders")}
            breadcrumbItem={props.t("Orders")}
          />
          <Row className="mb-3 justify-content-end">
            <Col lg="3" sm="6" className="justify-content-end ps-xxl-5">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdownOpen}
                direction={"down"}
              >
                <DropdownToggle
                  className="d-flex justify-content-between w-100"
                  outline
                  style={{
                    border: "1px solid #E1E4F1",
                    borderRadius: "6px",
                    color: "#2D3748",
                    background: "#FFF",
                    minWidth: "200px",
                  }}
                >
                  <span className=" ms-2 me-1">Filters</span>
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  <label
                    disabled
                    className="d-flex align-items-center opacity-50 px-3 p-1"
                  >
                    <input type="checkbox" checked={true} className="me-3" />
                    <p className="mb-0 fw-bold" style={{ color: "#2D3748" }}>
                      New Orders
                    </p>
                  </label>
                  {/* <DropdownItem divider /> */}
                  <label className="d-flex align-items-center px-3 pb-1">
                    <input
                      type="checkbox"
                      checked={filterArray.includes("shipped") ? true : false}
                      //   onChange={() => null}
                      onChange={() => onClickFilter("shipped")}
                      className="me-3"
                    />
                    <p className="mb-0 fw-bold" style={{ color: "#2D3748" }}>
                      Shipped Orders
                    </p>
                  </label>
                  <label className="d-flex align-items-center px-3 pb-1">
                    <input
                      type="checkbox"
                      checked={filterArray.includes("returned") ? true : false}
                      onClick={() => onClickFilter("returned")}
                      className="me-3"
                    />
                    <p className="mb-0 fw-bold" style={{ color: "#2D3748" }}>
                      Returned Orders
                    </p>
                  </label>
                  <label className="d-flex align-items-center px-3 pb-1">
                    <input
                      type="checkbox"
                      checked={filterArray.includes("completed") ? true : false}
                      onClick={() => onClickFilter("completed")}
                      className="me-3"
                    />
                    <p className="mb-0 fw-bold" style={{ color: "#2D3748" }}>
                      Completed Orders
                    </p>
                  </label>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          {isLoaded && newOrders.length == 0 ? (
            <Row
              className="text-center justify-content-center"
              style={{ marginTop: "15%", marginBottom: "15%" }}
            >
              <Col className="my-5">
                <p
                  className="font-size-22  mb-3 fw-bolder text-capitalize"
                  style={{ color: "#243678" }}
                >
                  Sit back, relax, and enjoy the peace. No New Orders
                </p>
                <Button
                  className="fw-bold btnHoverOpacity btnfont mt-3"
                  style={{
                    background: "#243678",
                  }}
                >
                  SHOW ALL ORDERS
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <h5
                className="fw-bolder font-size-22"
                style={{ color: "#243678" }}
              >
                New Orders{" "}
                <span className="font-red" style={{ color: "#FF0000" }}>
                  ({newOrderCount})
                </span>
              </h5>
              {isLoaded ? (
                newOrders &&
                newOrders.map((item, index) => (
                  <OrderCard {...item} key={index} new={true} />
                ))
              ) : (
                <div>Nothing to display</div>
              )}
            </>
          )}
          {filterArray.includes("shipped") ? (
            shippedOrders.length == 0 ? (
              <>
                <h5
                  className="fw-bolder font-size-22 mt-5"
                  style={{ color: "#243678" }}
                >
                  Shipped Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({shippedOrderCount})
                  </span>
                </h5>
                <Row
                  className="text-center justify-content-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <Col className="my-5">
                    <p
                      className="font-size-22  mb-3 fw-bolder text-capitalize"
                      style={{ color: "#243678" }}
                    >
                      No orders shipped yet
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h5
                  className="fw-bolder font-size-22"
                  style={{ color: "#243678", marginTop: "5rem" }}
                >
                  Shipped Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({shippedOrderCount})
                  </span>
                </h5>
                {isLoaded2 ? (
                  shippedOrders &&
                  shippedOrders.map((item, index) => (
                    <OrderCard {...item} key={index} new={true} />
                  ))
                ) : (
                  <div>Nothing to display</div>
                )}
              </>
            )
          ) : (
            ""
          )}
          {filterArray.includes("returned") ? (
            returnedOrders.length == 0 ? (
              <>
                <h5
                  className="fw-bolder font-size-22 mt-5"
                  style={{ color: "#243678" }}
                >
                  Returned Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({returnedOrderCount})
                  </span>
                </h5>
                <Row
                  className="text-center justify-content-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <Col className="my-5">
                    <p
                      className="font-size-22  mb-3 fw-bolder text-capitalize"
                      style={{ color: "#243678" }}
                    >
                      No orders returned yet
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h5
                  className="fw-bolder font-size-22"
                  style={{ color: "#243678", marginTop: "5rem" }}
                >
                  Returned Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({returnedOrderCount})
                  </span>
                </h5>
                {isLoaded2 ? (
                  returnedOrders &&
                  returnedOrders.map((item, index) => (
                    <OrderCard {...item} key={index} new={true} />
                  ))
                ) : (
                  <div>Nothing to display</div>
                )}
              </>
            )
          ) : (
            ""
          )}

          {filterArray.includes("completed") ? (
            completedOrders.length == 0 ? (
              <>
                <h5
                  className="fw-bolder font-size-22 mt-5"
                  style={{ color: "#243678" }}
                >
                  Completed Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({completedOrderCount})
                  </span>
                </h5>
                <Row
                  className="text-center justify-content-center"
                  style={{ marginTop: "15%", marginBottom: "15%" }}
                >
                  <Col className="my-5">
                    <p
                      className="font-size-22  mb-3 fw-bolder text-capitalize"
                      style={{ color: "#243678" }}
                    >
                      No orders completed yet
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <h5
                  className="fw-bolder font-size-22"
                  style={{ color: "#243678", marginTop: "5rem" }}
                >
                  Completed Orders{" "}
                  <span className="font-red" style={{ color: "#FF0000" }}>
                    ({completedOrderCount})
                  </span>
                </h5>
                {isLoaded2 ? (
                  completedOrders &&
                  completedOrders.map((item, index) => (
                    <OrderCard {...item} key={index} new={true} />
                  ))
                ) : (
                  <div>Nothing to display</div>
                )}
              </>
            )
          ) : (
            ""
          )}

          <Modal isOpen={notifyModal} toggle={notifyToggle} centered={true}>
            <ModalBody className="py-4 px-5">
              <Row>
                <Col lg={12}>
                  <div className="text-center">
                    <p className="mb-3">STATUS CHANGE NOTIFICATION</p>
                    <h5 className="py-4">{notifyEvent?.message}</h5>
                    <p className="mb-3">Confirm status change?</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center mt-1">
                    <Button
                      style={{
                        background: "#E2E8F0",
                        color: "#243678",
                        border: "none",
                      }}
                      outline
                      className="btn btn-md btnHoverOpacity"
                      onClick={notifyToggle}
                    >
                      CANCEL{" "}
                    </Button>
                    <Button
                      style={{
                        background: "#243678",
                        border: "none",
                      }}
                      className="btn btn-md ms-4 btnHoverOpacity"
                      onClick={() => {
                        onClickEvents(notifyEvent?.event, notifyEvent?.item)
                      }}
                    >
                      PROCEED
                    </Button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={successModal} toggle={successToggle} centered={true}>
            <ModalBody className="py-4 px-5">
              <Row>
                <Col lg={12}>
                  <div className="d-flex flex-column align-items-center">
                    <svg className="cne_checkmark" viewBox="0 0 52 52">
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>

                  <div className="text-center my-3 ">
                    <div>
                      <p className="fw-bolder text-uppercase">
                        {successEvent?.item?.shipper}
                      </p>
                    </div>

                    <p className="fw-bolder text-uppercase">
                      {successEvent?.event} SUCCESSFULLY
                    </p>
                    <p className="my-3 mb-3">{successEvent?.message}</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <p
                        className="mb-0 mx-1 fw-bolder"
                        style={
                          successEvent?.event == "Invoiced" ||
                          successEvent?.item?.isInvoiced
                            ? { color: "#243678" }
                            : { color: "#E2E8F0" }
                        }
                      >
                        INV
                      </p>
                      {successEvent?.event == "Invoiced" ||
                      successEvent?.item?.isInvoiced ? (
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0909 1.5L5.09091 10.5L1 6.40909"
                            stroke="#00FF00"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        ""
                      )}

                      <svg
                        className="mx-3"
                        width="60"
                        height="15"
                        viewBox="0 0 63 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.09094 6L61.0909 6"
                          stroke="#243678"
                          strokeOpacity={
                            successEvent?.event == "Labeled" ||
                            successEvent?.item?.isLabeled
                              ? "1"
                              : "0.2"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M51.9605 1L61.0909 6L51.9605 11"
                          stroke="#243678"
                          strokeOpacity={
                            successEvent?.event == "Labeled" ||
                            successEvent?.item?.isLabeled
                              ? "1"
                              : "0.2"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p
                        className="mx-1 mb-0 fw-bolder"
                        style={
                          successEvent?.event == "Labeled" ||
                          successEvent?.item?.isLabeled
                            ? { color: "#243678" }
                            : { color: "#E2E8F0" }
                        }
                      >
                        LBL
                      </p>
                      {successEvent?.event == "Labeled" ||
                      successEvent?.item?.isLabeled ? (
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0909 1.5L5.09091 10.5L1 6.40909"
                            stroke="#00FF00"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        ""
                      )}

                      <svg
                        className="mx-3"
                        width="60"
                        height="15"
                        viewBox="0 0 63 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.09094 6L61.0909 6"
                          stroke="#243678"
                          strokeOpacity={
                            successEvent?.event == "Pickup Scheduled"
                              ? "1"
                              : "0.2"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M51.9605 1L61.0909 6L51.9605 11"
                          stroke="#243678"
                          strokeOpacity={
                            successEvent?.event == "Pickup Scheduled"
                              ? "1"
                              : "0.2"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p
                        className="mx-1 mb-0 fw-bolder"
                        style={
                          successEvent?.event == "Pickup Scheduled"
                            ? { color: "#243678" }
                            : { color: "#E2E8F0" }
                        }
                      >
                        SHP
                      </p>
                      {successEvent?.event == "Pickup Scheduled" && (
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0909 1.5L5.09091 10.5L1 6.40909"
                            stroke="#00FF00"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center mt-1">
                    <Button
                      style={
                        successEvent?.event == "Pickup Scheduled"
                          ? {
                              background: "#06B825",
                              fontWeight: 700,
                              color: "#2D3748",
                              border: "none",
                            }
                          : {
                              background: "#E2E8F0",
                              color: "#243678",
                              border: "none",
                            }
                      }
                      outline
                      className="btn btn-md btnHoverOpacity"
                      onClick={successToggle}
                    >
                      {successEvent?.event == "Pickup Scheduled"
                        ? "FINISH"
                        : "CLOSE"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withTranslation()(Orders)
