import React, { useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss";
import { get } from "../../helpers/api_helper";
import moment from 'moment';
import { useState } from "react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { useHistory, useParams } from 'react-router-dom'

const DespatchProductDetailsTables = () => {
  const history = useHistory()
  const { user_id, order_id, datefrom, dateto } = useParams();
  const [onward, setOnward] = useState([]);
  const [returns, setReturns] = useState([]);
  const [final, setFinal] = useState({});
  const getData = () => {
    console.log(user_id, order_id, datefrom, dateto, 'HEREEEEEEEEEEEEEE')
    const token = JSON.parse(localStorage.getItem("authUser"));
    get(`/api/v2/accounts/product/details/?user_id=${token.id}&order_id=${order_id}&datefrom=${datefrom}&dateto=${dateto}`).then(res => {
      setOnward(res.data.Onward);
      setReturns(res.data.Returns);
      setFinal(res.data);
    }).catch(err => {
      setOnward([]);
      setReturns([]);
      setFinal({});
    })
  }
  useEffect(() => {
    getData()
  }, [])
  const Header = () => {
    return (
      <tr className={`tr-border mb-2`}>
        <td>
          <h5 className="text-muted mb-0" >PART NO</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >VEHICLE</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >PRICE</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >SC</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >COM CH</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >FC</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >COL CH</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >PG CH</h5>
        </td>
        <td>
          <h5 className="text-muted mb-0" >CREDIT</h5>
        </td>
      </tr>)
  }
  const DataRows = (props) => {

    return (<>
      {props && props.data && props.data.map(data => {
        return (
          <tr className={`tr-border mb-2`}>
            <td>
              <h5 className="text-muted mb-0" >{data.seller_partno}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.make}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.selling_price}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.delivery_charge}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.commission_charge}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.fixed_charge}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.collection_charge}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.payment_charge}</h5>
            </td>
            <td>
              <h5 className="text-muted mb-0" >{data.vendor_earning}</h5>
            </td>
          </tr>
        )
      })}
    </>)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Data Tables |  BrakeUp</title>
        </MetaTags>

        <Row lg='12' className="m-5 text-center" ><h2>ORDER  DETAILS</h2></Row>
        <Row className=" justify-content-between" >
          <Col lg="4" className="">
            <p>Order No: {order_id}</p>
          </Col>
          <Col lg="4" className="" >
            <p>Date: {onward.length > 0 ?
              moment(onward[0].approved_date["$date"]).format("DD-MM-YYYY, h:mm:ss a")
              : ""
            }


            </p>
          </Col>
          <Col lg="3">
            <p>Invoice No: {onward.length > 0 ? onward[0].invoice_no : ""}</p>
          </Col>
          <Col lg="4">
            <p>Total Items: {final.total_items}</p>
          </Col>
          <Col lg="4">
            <p>Buyer Name: {onward.length > 0 ? onward[0].buyer_name : ""}</p>
          </Col>
          <Col lg="3">
            <p>Total Dispatch: {final.total_despatch_amount}</p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="table-responsive"></div>
            <Table className="project-list-table table-nowrap align-middle  table">
              <tbody>
                <Header />
                <DataRows data={onward} />
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><h5>RETURNS</h5></td>
                  <td></td>
                  <td></td>
                  <td></td></tr>
                <DataRows data={returns} />
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default DespatchProductDetailsTables
