import React, {  useEffect, useState } from "react"
import {
  Button,
  Col,
  Row,
  Input
} from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { error, success } from "toastr";
import { get, post } from "../../../helpers/api_helper";

 export const ModalPriceDetails = (props) => {
    const {
      buttonLabel,
      className,
      mainGst,
      mrp,
      selling_price
    } = props;
    const [basePrice, setBasePrice] = useState(0);
    const [gstPrice, setGstPrice] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const gst = 18;
    useEffect(()=>{
      if(props.selling_price){
        onChangeSellingPrice(props.selling_price, props.min_extimated_delivery_chrg)
      }
    }, [props.selling_price])
    
    const [profit, setProfit] = useState({
      scb:0,
      ccx:0, //12% of spx,
      gcc:0, //gst of ccx
      tcc:0, //ccx+gcc,
      scx:0, //get from ship rocket
      gsc:0, // gst of scx,
      tsc:0, //scx+gsc
      fcx:0, //3% of spx,
      gfc:0, //gst of fcx,
      tfc:0, //fcx+gfc
      cox:0, //2% of spx
      gccc:0, //gst of cox
      toc:0, // cox+gccc
      pgx:0, //2.5% of spx
      gpa:0, //gst of pgx
      tpc:0, //pgx+gpa
      fis:0//(spx+scb)-(tcc+tsc+tfc+toc+tpc)
    })

    const onChangeSellingPrice = (e, min_extimated_delivery_chrg) => {
      const sellPrice = parseFloat(e);
      const scb = 0;

      // const basePrice = ((sellPrice * 100)/(100 + parseInt(gst)));
      // As per discussion with anjali, minu, nikhil 
      const gstPrice = ((sellPrice/100)* parseInt(gst));
      const basePrice = parseFloat(sellPrice - gstPrice);

      const ccx = ((sellPrice/100)*12);
      const gcc = ((ccx/100)*gst);
      const tcc = ccx + gcc;

      const scx = min_extimated_delivery_chrg? parseFloat(min_extimated_delivery_chrg): 0 //value from ship rocket;
      const gsc = ((scx/100)*gst);
      const tsc = (scx+gsc);

      const fcx = ((sellPrice/100)*3);
      const gfc = ((fcx/100)*gst);
      const tfc = (fcx+gfc);

      const cox =  ((sellPrice/100)*2);
      const gccc = ((cox/100)*gst);
      const toc = (cox+gccc);

      const pgx = ((sellPrice/100)*2.5);
      const gpa = ((pgx/100)*gst);
      const tpc = (pgx+gpa);
      
      const fis = ((sellPrice+scb) -(tcc+tsc+tfc+toc+tpc));
      const price = {
        scb,
        ccx,
        gcc,
        tcc,
        scx,
        gsc,
        tsc,
        fcx,
        gfc,
        tfc,
        cox,
        gccc,
        toc,
        pgx,
        gpa,
        tpc,
        fis
      }
      setProfit(price);
      setBasePrice(basePrice.toFixed());
      setGstPrice(gstPrice.toFixed());
      setSellingPrice(e);
    }
    const onClickConfim = () =>{
      console.log(profit);
      if(!sellingPrice){
        error("please add selling price")
        return;
      }
      const data = {
        "user_id":props.user_id,
        "prod_id":props.prod_id,
        selling_price: sellingPrice,
        "calculator_details": {...profit}
      }
      post('/api/v2/products/updatevendorproducts/',data).then(res=>{
        success("Selling price Updated");
        props.toggle();
      }).catch(err=>{
        error("not able to edit selling price");
      })
      
    }
    return (
      <div>
        <Modal isOpen={props.modal} toggle={props.toggle} className={className} size="lg">
          <ModalHeader toggle={props.toggle}>PRICE DETAILS</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="4">
                <Row>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span style={{ fontWeight: "bold" }}>
                      BASE PRICE
                    </span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span>{basePrice}</span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span style={{ fontWeight: "bold" }}>
                      GST @ {mainGst}%
                    </span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span>{gstPrice}</span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span style={{ fontWeight: "bold" }}>
                      Selling Price
                    </span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                  {props.isEdit == true?<Input name="sellingPrice"
                      type="number"
                      value={sellingPrice}
                      onChange={(e) => onChangeSellingPrice(e.target.value, props.min_extimated_delivery_chrg)}></Input>
                      :
                  <span>{sellingPrice}</span>}
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px"}}>
                    <span style={{ fontWeight: "bold" }}>
                      MRP
                    </span>
                  </Col>
                  <Col lg="6" style={{marginBottom:"10px", fontWeight: "bold"}}>
                    <span>{mrp}</span>
                  </Col>
                </Row>
              </Col>
              <Col lg="8">
                <Row>
                  <Col lg="12" style={{textAlign:"center"}}><h5>PROFIT CALCULATOR</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>Price Details</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>Amount</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>Selling Price</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{sellingPrice?sellingPrice: 0}</h5></Col>

                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Shipping Charge to buyer</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.scb? profit.scb.toFixed(2): 0}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Commision charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.ccx? profit.ccx.toFixed(2): 0}</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>GST@{gst}% on Commision charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.gcc? profit.gcc.toFixed(2): 0}</h5></Col>
                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Total Commision charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.tcc? profit.tcc.toFixed(2): 0}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Shipping charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.scx? profit.scx: 0}</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>GST@{gst}% on Shipping charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.gsc? profit.gsc.toFixed(2):0}</h5></Col>
                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Total Shipping charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.tsc.toFixed(2)}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Fixed charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.fcx.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>GST@{gst}% on Fixed charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.gfc.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Total Fixed charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.tfc.toFixed(2)}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Collection charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.cox.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>GST@{gst}% on Collection charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.gccc.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Total Collection charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.toc.toFixed(2)}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Payment Gateway charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.pgx.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right"}}><h5>GST@{gst}% on PG charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.gpa.toFixed(2)}</h5></Col>
                  <Col lg="7" style={{textAlign:"right", marginBottom:"10px"}}><h5>Total PG charges</h5></Col>
                  <Col lg="5" style={{textAlign:"left", marginBottom:"10px"}}><h5>{profit.tpc.toFixed(2)}</h5></Col>

                  <Col lg="7" style={{textAlign:"right"}}><h5>Final settlement</h5></Col>
                  <Col lg="5" style={{textAlign:"left"}}><h5>{profit.fis.toFixed(2)}</h5></Col>
                  <div style={{textAlign:"center"}}>
                  <span style={{color:"red"}}>(amount may vary according to the shipping charge)</span>
                  </div>

                  

                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {props.isEdit == true && <Button color="primary" onClick={()=>onClickConfim()}>Save</Button>}
            <Button color="secondary" onClick={props.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }