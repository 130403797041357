import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button
} from "reactstrap"
// import companies from "assets/images/users"

import {Link} from 'react-router-dom';

import avatar from "assets/images/users/avatar-1.jpg"
import avatar1 from "assets/images/users/avatar-2.jpg"
import avatar2 from "assets/images/users/avatar-3.jpg"
import { get } from "../../helpers/api_helper";

//import action
import TableRow from './orderRow'



//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {useHistory }  from 'react-router-dom'
import moment from "moment";

//redux

const OrderDetail = props => {

  const history = useHistory()
  const [order, setOrder] = useState("")


  useEffect(() => {
    let order_id = window.location.pathname.split('/').reverse()[0]

    get(`/api/v2/order/orderdetails/?order_no=${order_id}`)
    .then(response => {
      setOrder(response.data[0])
      // console.log(moment(response.data[0]['created_at']['$date']).format('LLLL'))
    })
    .catch(error => {
  
    })


  }, [])







  return (
    <React.Fragment>
      <div className="page-content" style={{background:"white"}}>
        <MetaTags>
          <title>Orders Detail | BrakeUp</title>
        </MetaTags>
        {
          order &&
          <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Order Detail")}
            breadcrumbItem={props.t("Order Detail")}
          />
          <br/>

          <Row>
              <Col>
              <Container>

              
              <img
                src={order.image}
                alt=""
                className="avatar-xl mb-3"
                />
                {/* <img
                src={avatar1}
                alt=""
                className="avatar-xl mb-3"
                />
                <img
                src={avatar2}
                alt=""
                className="avatar-xl mb-3"
                /> */}

                </Container>
                
              </Col>
              

              <Col>
              <div className="text-muted mb-1 p-2 bordered-div" >
                  <h3>
                        Customer Vehicle
                  </h3>
                    
                </div>

              <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start" >
              <p>Date and Time <br/> {moment(order['created_at']['$date']).format('LLLL')} </p><br/>
                  
                    {/* <p>{order['created_at']['$date']}</p> */}
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start" >
                <p>Order Number <br/>
                {order.order_id}</p>
                </div>
                {/* <div className="text-muted mb-1 p-2 bordered-div" >
                Invoice
                </div> */}
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start" >
                <p>Buyer Address <br/>
                {order.billing_address}</p>
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start" >
                <p>Seller Address <br/>
                {order.vendor_address}</p>
                </div>
                <div className="text-muted mb-1 p-2 bordered-div d-flex justify-content-start" >
                <p>Status <br/>
                {order.order_status}</p>
                </div>
                            
              </Col>

              <Col >
                  <div className='margin-left-30'>

                  
              <p className="text-muted mb-0">
                    Name<br/>
                    {order.prod_name}
                </p>
                <br/>
                <p className="text-muted mb-0">
                Brand Name<br/>
                {order.brand}
                </p>
                <br/>
                <p className="text-muted mb-0">
                BrakeUp part number<br/>
                {order.brakeup_part_no}
                </p>
                <br/>
                <p className="text-muted mb-0">
                Product type<br/>
                {order.type}
                </p>
                <br/>
                <p className="text-muted mb-0">
                Product Category<br/>
                {order.category}
                </p>
                <br/>
                <p className="text-muted mb-0">
                Axle
                </p>
                <br/>

                <p className="text-muted mb-0">
                Seller Part Number<br/>
                {order.seller_part_no}
                </p>
                <br/>
                <p className="text-muted mb-0">
                Breakup Part Number<br/>
                {order.brakeup_part_no}
                </p>
                <br/>
                <p className="text-muted mb-0">
                OEM Number<br/>
                {order.oem_part_no}
                </p>
                </div>
              </Col>

              <Col>
                <div className="text-muted mb-1 p-2 bordered-div " >
                    <div>
                        <h4>Price Details</h4>
                        <br/>
                        <p className="text-muted mb-0">MRP : {order.order_total}</p>
                        <p className="text-muted mb-0">Selling Price</p>
                        <p className="text-muted mb-0">Normal Price: {order.price}</p>
                        <p className="text-muted mb-0">Last Price</p>
                        <p className="text-muted mb-0">GST</p>
                        <p className="text-muted mb-0">Profit Calculator</p>
                    </div>
                </div>

              </Col>
          </Row>
          <div className="d-flex flex-wrap gap-2" style={{marginBottom:"120px", float:"right"}}>
                      <Button
                        type="submit"
                        color="secondary"
                        className=" "
                        onClick={()=> history.push("/cancel-order")}
                      >
                        Cancel Order
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        onClick={()=> history.push("/orders")}
                      >
                        Replacement
                    </Button>
          </div>
        </Container>
        }

      </div>

 
    </React.Fragment>
  )
}

OrderDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderDetail)
