import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  DropdownItem,
  Row,
  Col,
  Table,
  Button,
  Label

} from "reactstrap"
import companies from "assets/images/companies"

import {Link} from 'react-router-dom';
import moment from 'moment';
import Select from "react-select"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import {useHistory }  from 'react-router-dom';








const CheckBox = () => (
    <input
    type="checkbox"
    className="form-check-input"
    id="customCheckcolor1"
    checked={true}
    onChange={() => {
    }}
/>
)
const AddRoles = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [selectedFilter,setSelectedFilter] = useState('');
    const history = useHistory()
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }))



  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch]);


  const onClickRow = () => {
      // history.push('/orders/12')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Seller | BrakeUp</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Add Role")}
            breadcrumbItem={props.t("Add Role")}
          />


        <br/>
        <br/>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                <Table>
                    <thead>
                        <tr>
                        <th></th>
                        <th>View</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Create/Approve</th>
                        <th>Full Access</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">users</td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td>More Permissions</td>
                        </tr>
                        <tr>
                            <td scope="row">Vendors</td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td>More Permissions</td>
                        </tr>
                        <tr>
                            <td scope="row">Sellers</td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td>More Permissions</td>
                        </tr>
                        <tr>
                            <td scope="row">Buyers</td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td><CheckBox/></td>
                            <td>More Permissions</td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
              </div>
            </Col>
          </Row>




        </Container>
      </div>

 
    </React.Fragment>
  )
}

AddRoles.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(AddRoles)
