import React, { useState ,useRef} from "react"
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CustomInput, CustomSelect } from "../Ecommerce/customInputs";
import {
    Button,
    Label,
    Row,
    Col
} from "reactstrap";
import { submit } from "redux-form";

const AddAddress = (props) => {
    const { register, handleSubmit: handleSubmit2
        , errors: errors2, control: control2, reset} = useForm({
            mode: 'onChange'
        });

    const onSubmit = (values) => {
        props.addressDetails(values);
        reset({
            pickup_location: '',
            address: '',
            address_2: '',
            city: '',
            state: '',
            pincode: '',
          })

    }
    const validateAddress = (chara) => {
        // Phone number validations
        if (chara && chara.length >= 10) {
            return true
        }
        return false;
    };
    const validatePinCode = (number) => {
        // Phone number validations
        if (number && number.length == 6) {
            return true
        }
        return false;
    };
    return (
        <form>
            <Row>
                <h4>Pickup Address</h4>
                <Col lg="6">
                    <div className="mb-3">
                        <Label htmlFor="productname">Pickup location</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="pickup_location"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: true }}
                        />
                        {errors2.pickup_location && <span style={{ color: "red" }}>pickup location is required.</span>}
                    </div>
                </Col>
                <Col lg="6">
                    <div className="mb-3">
                        <Label htmlFor="productname">Address 1</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="address"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: true, validate: validateAddress }}
                        />
                        {errors2.address &&
                            errors2.address.type &&
                            errors2.address.type == "required" ? <span style={{ color: "red" }}>address is required.</span> : ""}
                        {errors2.address &&
                            errors2.address.type &&
                            errors2.address.type == "validate"
                            ?
                            <span style={{ color: "red" }}>Address cannot be less than 10 characters </span> : ""}

                    </div>
                </Col>
                <Col lg="6">
                    <div className="mb-3">
                        <Label htmlFor="productname">Address 2</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="address_2"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: false }}
                        />
                        {errors2.address_2 && <span style={{ color: "red" }}>Address 2 is required.</span>}
                    </div>
                </Col>
                <Col lg="3">
                    <div className="mb-3">
                        <Label htmlFor="productname">City</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="city"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: true }}
                        />
                        {errors2.city && <span style={{ color: "red" }}>City is required.</span>}
                    </div>
                </Col>
                <Col lg="3">
                    <div className="mb-3">
                        <Label htmlFor="productname">State</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="state"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: true }}
                        />
                        {errors2.state && <span style={{ color: "red" }}>State is required.</span>}
                    </div>
                </Col>
                {/* <Col lg="3">
            <div className="mb-3">
                <Label htmlFor="productname">Country</Label>
                <Controller
                    as={<CustomInput />}
                    defaultValue={null}
                    name="country"
                    onChange={([e]) => {
                        return { value: e };
                    }}
                    control={control2}
                    rules={{ required: true }}
                />
                {errors2.country && <span style={{ color: "red" }}>Country is required.</span>}
            </div>
            </Col> */}
                <Col lg="3">
                    <div className="mb-3">
                        <Label htmlFor="productname">Pin Code</Label>
                        <Controller
                            as={<CustomInput />}
                            defaultValue={null}
                            name="pincode"
                            onChange={([e]) => {
                                return { value: e };
                            }}
                            control={control2}
                            rules={{ required: true, validate: validatePinCode }}
                            type={"number"}
                        />
                        {errors2.pincode &&
                            errors2.pincode.type &&
                            errors2.pincode.type == "required"
                            ?
                            <span style={{ color: "red" }}>number is required</span> : ""}
                        {errors2.pincode &&
                            errors2.pincode.type &&
                            errors2.pincode.type == "validate"
                            ?
                            <span style={{ color: "red" }}>Six digits are allowed</span> : ""}
                    </div>
                </Col>
                <Col lg="2" style={{ marginTop: "26px" }}>
                    <Button color="primary" onClick={handleSubmit2((d) => onSubmit(d))}>Add</Button>
                </Col>
            </Row>
        </form>
    )
}
export default AddAddress;